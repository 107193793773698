import React, { Component } from "react";
import I18n from "../../../config/locales";
import {
  relativeTimeFromNow,
  timeDisplay,
  timezoneIfDifferent
} from "../../../config/datetime";
import Flood from "images/currents/flood.png";
import Slack from "images/currents/slack.png";

class Now extends Component {
  currentHeadline(current, type) {
    if (current.max_velocity === null) {
      return (
        <div>
          {I18n.t(type) + " - " + I18n.t("slack")}
          <img className="ml-2 mb-2" src={Slack} />
        </div>
      );
    } else if (current.max_velocity < 0) {
      return (
        <div>
          {I18n.t(type) + " - " + I18n.t("ebb")}
          <img className="ml-2 mb-2" src={Flood} />
        </div>
      );
    } else {
      return (
        <div>
          {I18n.t(type) + " - " + I18n.t("flood")}
          <img className="ml-2 mb-2" src={Flood} />
        </div>
      );
    }
    return str;
  }

  render() {
    const { currentStation } = this.props;
    return (
      <div>
        <div className="card text-center bg-tides1 mb-1 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.currentHeadline(
                currentStation.last_current,
                "last_current"
              )}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(currentStation.last_current.timestamp)}
                {timezoneIfDifferent(currentStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(currentStation.last_current.timestamp)}
            </div>
          </div>
        </div>
        <div className="card text-center bg-tides2 mb-1 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.currentHeadline(
                currentStation.next_current,
                "next_current"
              )}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(currentStation.next_current.timestamp)}
                {timezoneIfDifferent(currentStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(currentStation.next_current.timestamp)}
            </div>
          </div>
        </div>
        <div className="card text-center bg-tides4 mb-1 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.currentHeadline(
                currentStation.following_current,
                "following_current"
              )}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(currentStation.following_current.timestamp)}
                {timezoneIfDifferent(currentStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(currentStation.following_current.timestamp)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Now;
