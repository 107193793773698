import React, { Component } from "react";

class Breadcrumbs extends Component {
  countryBreadcrumb(linked = false) {
    const { results } = this.props;
    if (linked) {
      return (
        <li className="breadcrumb-item">
          <a href={"/countries/" + results.country_id}>
            {results.country_name}
          </a>
        </li>
      );
    } else {
      return <li className="breadcrumb-item active">{results.name}</li>;
    }
  }

  stateBreadcrumb(linked = false) {
    const { results } = this.props;
    if (linked) {
      return (
        <li className="breadcrumb-item">
          <a href={"/states/" + results.state_id}>{results.state_name}</a>
        </li>
      );
    } else {
      return <li className="breadcrumb-item active">{results.name}</li>;
    }
  }

  cityBreadcrumb(linked = false) {
    const { results } = this.props;
    if (linked) {
      return (
        <li className="breadcrumb-item">
          <a href={"/cities/" + results.city_id}>{results.city_name}</a>
        </li>
      );
    } else {
      return <li className="breadcrumb-item active">{results.name}</li>;
    }
  }

  stationBreadcrumb() {
    const { results } = this.props;
    return <li className="breadcrumb-item active">{results.name}</li>;
  }

  getBreadcrumbs() {
    const { results } = this.props;
    if (results.country_code !== undefined) {
      // it's a country
      return (
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">{this.countryBreadcrumb(false)}</ol>
            </nav>
          </div>
        </div>
      );
    } else if (results.abbr !== undefined) {
      // it's a state
      return (
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {this.countryBreadcrumb(true)}
                {this.stateBreadcrumb()}
              </ol>
            </nav>
          </div>
        </div>
      );
    } else if (
      (results.last_tide !== undefined || results.last_current !== undefined) &&
      results.city_id === null &&
      results.state_id === null
    ) {
      // it's a stattion with a country but no state or city
      return (
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {this.countryBreadcrumb(true)}
                {this.stationBreadcrumb()}
              </ol>
            </nav>
          </div>
        </div>
      );
    } else if (
      (results.last_tide !== undefined || results.last_current !== undefined) &&
      results.city_id === null
    ) {
      // it's a station with a state and country but no city
      return (
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {this.countryBreadcrumb(true)}
                {this.stateBreadcrumb(true)}
                {this.stationBreadcrumb()}
              </ol>
            </nav>
          </div>
        </div>
      );
    } else if (
      results.last_tide !== undefined ||
      results.last_current !== undefined
    ) {
      // it's a station with a city, state, and country
      return (
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {this.countryBreadcrumb(true)}
                {this.stateBreadcrumb(true)}
                {this.cityBreadcrumb(true)}
                {this.stationBreadcrumb()}
              </ol>
            </nav>
          </div>
        </div>
      );
    } else if (
      results.country_id !== undefined &&
      results.state_id !== undefined
    ) {
      // it's a city
      return (
        <div className="row">
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {this.countryBreadcrumb(true)}
                {this.stateBreadcrumb(true)}
                {this.cityBreadcrumb()}
              </ol>
            </nav>
          </div>
        </div>
      );
    } else return null;
  }

  render() {
    const { results } = this.props;
    if (
      results === null ||
      window.location.pathname == "/" ||
      window.location.pathname == "/countries"
    ) {
      return null;
    } else {
      return this.getBreadcrumbs();
    }
  }
}

export default Breadcrumbs;
