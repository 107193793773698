import React from "react";
import I18n from "../../config/locales";

const Countries = ({ countries }) => {
  if (countries === undefined || countries.length == 0) {
    return <div />;
  } else {
    const userLocale = window.navigator.language;
    const countryCode = userLocale.substr(userLocale.indexOf("-") + 1);
    var orderedCountries = [];
    for (var i = 0; i < countries.length; i++) {
      if (countries[i].country_code == countryCode) {
        orderedCountries.unshift(countries[i]);
      } else {
        orderedCountries.push(countries[i]);
      }
    }
    return (
      <div className="card bg-tides3 mb-2">
        <div className="card-body text-white">
          <h2 className="pl-3">{I18n.t("by_country")}</h2>
          <ul className="list-group list-group-flush">
            {orderedCountries.map(country => (
              <a
                href={"/countries/" + country.id}
                className="list-group-item list-group-item-action bg-tides2 text-white"
                key={country.id}
              >
                {country.name}
              </a>
            ))}
          </ul>
        </div>
      </div>
    );
  }
};

export default Countries;
