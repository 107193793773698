import React, { Component } from "react";
import I18n from "../../config/locales";
import { Helmet } from "react-helmet";

const defaultTitle = I18n.t("subtitle");

class Title extends Component {
  getTitle() {
    if (this.props.results !== null && this.props.results.name !== undefined) {
      return this.props.results.name + " " + I18n.t("tides");
    } else if (
      window.location.pathname == "/" ||
      window.location.pathname == "/countries"
    ) {
      return defaultTitle;
    }
    return null;
  }

  getTitleTag(title) {
    if (title === null || title === defaultTitle) {
      return I18n.t("app_name");
    } else {
      return title + " - " + I18n.t("app_name");
    }
  }

  render() {
    const title = this.getTitle();
    return (
      <div>
        <Helmet>
          <title>{this.getTitleTag(title)}</title>
        </Helmet>
        <h1>{title}</h1>
      </div>
    );
  }
}

export default Title;
