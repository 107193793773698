import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CurrentStation from "./stations/current";
import Home from "./home";
import Privacy from "./home/Privacy";
import Support from "./home/Support";
import Terms from "./home/Terms";
import TideStation from "./stations/tide";

class AppNavigation extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/cities/:id" element={<Home endpoint="city"/>}/>
          <Route path="/countries" element={<Home endpoint="countries"/>}/>
          <Route path="/countries/:id" element={<Home endpoint="country"/>}/>
          <Route path="/current_stations/:id" element={<CurrentStation/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/states/:id" element={<Home endpoint="state"/>}/>
          <Route path="/support" element={<Support/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/tide_stations/:id" element={<TideStation/>} />
          <Route path="/" element={<Home endpoint="lookup"/>}/>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default AppNavigation;
