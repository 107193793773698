import React from "react";

const Support = () => {
  return (
    <div>
      <div className="d-flex justify-content-center my-3">
        <h1>Support</h1>
      </div>
      <p>
        Email <a href="mailto:support@tidesnear.me">support@tidesnear.me</a> for
        support!
      </p>
    </div>
  );
};

export default Support;
