import React, { useEffect, useState } from "react";
import LocationList from "../locations";
import { useParams } from "react-router-dom";
import Map from "../locations/Map";
import Breadcrumbs from "./Breadcrumbs";
import Title from "./Title";
import MobileStoreButtons from "./MobileStoreButtons";

export default function Home({endpoint}) {
  let apiUrl = buildApiUrl(endpoint);
  const [lookupResults, loading, hasError] = useFetch(apiUrl);
  return (
    <>
      {
        lookupResults == null ? <div/> : <div>{renderLookupResults(lookupResults)}</div>
      }
    </>
  );
}

function buildApiUrl(endpoint) {
  let apiUrl = window.location.origin + "/api/v2/locations/" + endpoint;
  let { id } = useParams();
  if (id != null) {
    apiUrl += "/" + id;
  }
  let language_code = window.navigator.userLanguage || window.navigator.language || "en";
  apiUrl += "?language=" + language_code;
  return apiUrl;
}

function renderLookupResults(lookupResults) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md d-flex justify-content-center my-3">
          <Title results={lookupResults} />
        </div>
      </div>
      <Breadcrumbs results={lookupResults} />
      <div className="row">
        <div className="col-md">
          <LocationList results={lookupResults} />
        </div>
        <div className="col-md">
          <Map results={lookupResults} />
          <MobileStoreButtons />
        </div>
      </div>
    </div>
  );
}

function useFetch(url, opts) {
  const [lookupResults, setlookupResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setLoading(true)
    // get country results first
    fetch(url, opts)
      .then((res) => res.json())
      .then((data) => {
        setlookupResults(data)
        setLoading(false)
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      });
    // then try for location-based results
    if (url.includes("locations/lookup")) {
      getPosition()
        .then((position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          fetch(url + `&lat=${lat}&lng=${lng}`, opts)
            .then((res) => res.json())
            .then((data) => {
              data.userLat = lat;
              data.userLng = lng;
              setlookupResults(data)
              setLoading(false)
            })
            .catch((err) => {
              fetch(url, opts)
                .then((res) => res.json())
                .then((data) => {
                  setlookupResults(data)
                  setLoading(false)
                })
            })
        });
    }
  }, [ url ]);
  return [ lookupResults, loading, hasError ];
}

function getPosition() {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: false,
      timeout: 10000,
      maximumAge: Infinity,
    });
  }).catch(console.log);
};