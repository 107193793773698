export default {
  translation: {
    app_name: "Getijden in de buurt",
    app_version: "Appversie",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Bijdrage",
    by_country: "Op land",
    cities: "Provincies",
    clear_recent_currents: "Recente stromingstations wissen",
    clear_recent_tides: "Recente getijdenstations wissen",
    connection_error: "Verbindingsfout",
    contact_support: "Contact opnemen",
    current_station: "Stromingstation",
    current_stations: "Stromingstations",
    currents: "Stromingen",
    distance: "Afstand",
    ebb: "Eb",
    favorite_currents: "Favoriete stromingen",
    favorite_tides: "Favoriete getijden",
    flood: "Vloed",
    following_current: "Komende stroming",
    hide: "verbergen",
    high: "Hoogtij",
    home: "Home",
    hour_ago: "{{hour}} uur geleden",
    hour_from_now: "Over {{hour}} uur",
    hour_minute_ago: "{{hour}} uur en {{minute}} minuut geleden",
    hour_minute_from_now: "Over {{hour}} uur en {{minute}} minuut",
    hour_minutes_ago: "{{hour}} uur en {{minute}} minuten geleden",
    hour_minutes_from_now: "Over {{hour}} uur en {{minute}} minuten",
    hours_ago: "{{hour}} uur geleden",
    hours_from_now: "Over {{hour}} uur",
    hours_minute_ago: "{{hour}} uur en {{minute}} minuut geleden",
    hours_minute_from_now: "Over {{hour}} uur en {{minute}} minuut",
    hours_minutes_ago: "{{hour}} uur en {{minute}} minuten geleden",
    hours_minutes_from_now: "Over {{hour}} uur en {{minute}} minuten",
    just_now: "Zojuist",
    kms: "km",
    kms_away: "Op {{distance}} km afstand",
    knots: "knp",
    last_current: "Vorige stroming",
    last_tide: "Vorige getijde",
    loading: "Laden...",
    low: "Laagtij",
    map: "Kaart",
    meters: "m",
    miles: "mijl",
    miles_away: "Op {{distance}} mijl afstand",
    minute_ago: "{{minute}} minuut geleden",
    minute_from_now: "Over {{minute}} minuut",
    minutes_ago: "{{minute}} minuten geleden",
    minutes_from_now: "Over {{minute}} minuten",
    moon: "Maan",
    moonrise_now: "Maansopgang",
    moonrise_week: "Opgang",
    moonset_now: "Maansondergang",
    moonset_week: "Ondergang",
    nearby_currents: "Stroming in de buurt",
    nearby_tides: "Getijden in de buurt",
    next_current: "Volgende stroming",
    next_tide: "Volgende getijde",
    now: "Nu",
    recent_currents: "Recente stromingen",
    recent_tides: "Recente getijden",
    recents: "Recent",
    search: "Zoeken",
    search_placeholder: "Zoek naar getijdenstations",
    settings: "Instellingen",
    show: "tonen",
    slack: "Stil water",
    somethings_wrong: "Er is een fout opgetreden",
    states: "Steden",
    sun: "Zon",
    sunrise_now: "Zonsopgang",
    sunrise_week: "Opgang",
    sunset_now: "Zonsondergang",
    sunset_week: "Ondergang",
    this_station: "Dit station",
    tide_station: "Getijdenstation",
    tide_stations: "Getijdenstations",
    tides: "Getijden",
    today: "Vandaag",
    tomorrow: "Morgen",
    try_again: "Opnieuw",
    turn_off_ads: "Advertenties drie dagen uitschakelen",
    unit: "Eenheid",
    update1:
      "Favorieten toevoegen, verbeterde kaartfunctionaliteit, metriek/imperiaal stelsel.",
    update2: "Abonnement om advertenties te verwijderen.",
    update3: "Verholpen bugs en prestatieverbeteringen.",
    watch_rewarded_video: "Bekijk beloningsvideo",
    week: "Week"
  }
};
