export default {
  translation: {
    app_name: "Tidevand nær mig",
    app_version: "App-version",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Bidrag",
    by_country: "Efter land",
    cities: "Byer",
    clear_recent_currents: "Ryd nylige strømstationer",
    clear_recent_tides: "Ryd nylige tidevandsstationer",
    connection_error: "Forbindelsesfejl",
    contact_support: "Kontakt support",
    current_station: "Strømstation",
    current_stations: "Strømstationer",
    currents: "Strømme",
    distance: "Afstand",
    ebb: "Ebbe",
    favorite_currents: "Favorit strømme",
    favorite_tides: "Favorit tidevande",
    flood: "Flod",
    following_current: "Følgende strøm",
    hide: "skjul",
    high: "Højvande",
    home: "Startside",
    hour_ago: "{{hour}} time siden",
    hour_from_now: "{{hour}} time fra nu",
    hour_minute_ago: "{{hour}} time, {{minute}} minut siden",
    hour_minute_from_now: "{{hour}} time, {{minute}} minut fra nu",
    hour_minutes_ago: "{{hour}} time, {{minute}} minutter siden",
    hour_minutes_from_now: "{{hour}} time, {{minute}} minutter fra nu",
    hours_ago: "{{hour}} timer siden",
    hours_from_now: "{{hour}} timer fra nu",
    hours_minute_ago: "{{hour}} timer, {{minute}} minut siden",
    hours_minute_from_now: "{{hour}} timer, {{minute}} minut fra nu",
    hours_minutes_ago: "{{hour}} timer, {{minute}} minutter siden",
    hours_minutes_from_now: "{{hour}} timer, {{minute}} minutter fra nu",
    just_now: "Lige nu",
    kms: "km",
    kms_away: "{{distance}} km væk",
    knots: "kn",
    last_current: "Forrige strøm",
    last_tide: "Sidste lavvande",
    loading: "Indlæser...",
    low: "Lavvande",
    map: "Kort",
    meters: "m",
    miles: "mil",
    miles_away: "{{distance}} mil væk",
    minute_ago: "{{minute}} minut siden",
    minute_from_now: "{{minute}} minut fra nu",
    minutes_ago: "{{minute}} minutter siden",
    minutes_from_now: "{{minute}} minutter fra nu",
    moon: "Måne",
    moonrise_now: "Månen står op",
    moonrise_week: "Opgang",
    moonset_now: "Månen går ned",
    moonset_week: "Nedgang",
    nearby_currents: "Kommende strømme",
    nearby_tides: "Kommende tidevand",
    next_current: "Næste strøm",
    next_tide: "Næste tidevand",
    now: "Nu",
    recent_currents: "Nylige strømme",
    recent_tides: "Nylige tidevand",
    recents: "Nylige",
    search: "Søg",
    search_placeholder: "Søg efter tidevandsstationer",
    settings: "Indstillinger",
    show: "vis",
    slack: "Stillestående vand",
    somethings_wrong: "Der opstod en fejl",
    states: "Stater",
    sun: "Sol",
    sunrise_now: "Solopgang",
    sunrise_week: "Opgang",
    sunset_now: "Solnedgang",
    sunset_week: "Nedgang",
    this_station: "Denne station",
    tide_station: "Tidevandsstation",
    tide_stations: "Tidevandsstationer",
    tides: "Tidevand",
    today: "I dag",
    tomorrow: "I morgen",
    try_again: "Forsøg igen",
    turn_off_ads: "Slå reklamer fra i tre dage",
    unit: "Enhed",
    watch_rewarded_video: "Se belønnet video",
    week: "Uge"
  }
};
