import React from "react";
import TideStations from "./TideStations";
import CurrentStations from "./CurrentStations";
import Countries from "./Countries";
import States from "./States";
import Cities from "./Cities";
import Button from "react-bootstrap/Button";

const LocationList = ({ results }) => {
  if (results === null) {
    return <div />;
  } else {
    const tideStations = results.tide_stations;
    const currentStations = results.current_stations;
    const countries = results.countries;
    const states = results.states;
    const stateTerm = results.state_term;
    const cities = results.cities;
    const nearby = states === undefined && cities === undefined;
    return (
      <div>
        <States states={states} stateTerm={stateTerm} />
        <Cities cities={cities} />
        <TideStations tideStations={tideStations} nearby={nearby} />
        <CurrentStations currentStations={currentStations} nearby={nearby} />
        <Countries countries={countries} />
      </div>
    );
  }
};

export default LocationList;
