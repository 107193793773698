export default {
  translation: {
    app_name: "潮汐在身邊",
    app_version: "App 版本",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "屬性",
    by_country: "依國家／地區",
    cities: "城市",
    clear_recent_currents: "清除最近的潮位站",
    clear_recent_tides: "清除最近的驗潮站",
    connection_error: "連接錯誤",
    contact_support: "聯絡客服",
    current_station: "潮位站",
    current_stations: "潮位站",
    currents: "近海流",
    distance: "距離",
    ebb: "退潮",
    favorite_currents: "喜愛海流",
    favorite_tides: "喜愛潮汐",
    flood: "漲潮",
    following_current: "下下次海流",
    hide: "隱藏",
    high: "滿",
    home: "首頁",
    hour_ago: "{{hour}} 小時前",
    hour_from_now: "{{hour}} 小時後",
    hour_minute_ago: "{{hour}} 小時 {{minute}} 分鐘前",
    hour_minute_from_now: "{{hour}} 小時 {{minute}} 分鐘後",
    hour_minutes_ago: "{{hour}} 小時 {{minute}} 分鐘前",
    hour_minutes_from_now: "{{hour}} 小時 {{minute}} 分鐘後",
    hours_ago: "{{hour}} 小時前",
    hours_from_now: "{{hour}} 小時後",
    hours_minute_ago: "{{hour}} 小時 {{minute}} 分鐘前",
    hours_minute_from_now: "{{hour}} 小時 {{minute}} 分鐘後",
    hours_minutes_ago: "{{hour}} 小時 {{minute}} 分鐘前",
    hours_minutes_from_now: "{{hour}} 小時 {{minute}} 分鐘後",
    just_now: "剛剛",
    kms: "公里",
    kms_away: "距離 {{distance}} 公里",
    knots: "結",
    last_current: "上次海流",
    last_tide: "上次潮汐",
    loading: "載入中",
    low: "乾",
    map: "地圖",
    meters: "米",
    miles: "哩",
    miles_away: "距離 {{distance}} 哩",
    minute_ago: "{{minute}} 分鐘前",
    minute_from_now: "{{minute}} 分鐘後",
    minutes_ago: "{{minute}} 分鐘前",
    minutes_from_now: "{{minute}} 分鐘後",
    moon: "月亮",
    moonrise_now: "月出",
    moonrise_week: "月出",
    moonset_now: "月落",
    moonset_week: "月落",
    nearby_currents: "附近海流",
    nearby_tides: "附近潮汐",
    next_current: "下次海流",
    next_tide: "下次潮汐",
    now: "現在",
    recent_currents: "最近海流",
    recent_tides: "最近潮汐",
    recents: "最近記錄",
    search: "搜尋",
    search_placeholder: "搜索潮汐站",
    settings: "設定",
    show: "顯示",
    slack: "憩流",
    somethings_wrong: "發生錯誤",
    states: "州",
    sun: "太陽",
    sunrise_now: "日出",
    sunrise_week: "日出",
    sunset_now: "日落",
    sunset_week: "日落",
    this_station: "這個測站",
    tide_station: "驗潮站",
    tide_stations: "驗潮站",
    tides: "潮汐",
    today: "今天",
    tomorrow: "明天",
    try_again: "再試一次",
    turn_off_ads: "停止顯示廣告三天",
    unit: "單位",
    watch_rewarded_video: "觀賞有獎影片",
    week: "週"
  }
};
