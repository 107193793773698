export default {
  translation: {
    app_name: "Mareas cercanas",
    app_version: "Versión de la aplicación",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Attribution",
    by_country: "Por país",
    cities: "Estados",
    clear_recent_currents: "Borrar estaciones de corrientes recientes",
    clear_recent_tides: "Borrar estaciones de mareas recientes",
    connection_error: "Error de conexión",
    contact_support: "Contactar con la asistencia",
    current_station: "Estación de corrientes",
    current_stations: "Estaciones de corrientes",
    currents: "Corrientes",
    distance: "Distancia",
    ebb: "Reflujo",
    favorite_currents: "Corrientes favoritas",
    favorite_tides: "Mareas favoritas",
    flood: "Flujo",
    following_current: "Siguiente corriente",
    hide: "ocultar",
    high: "Alta",
    home: "Home",
    hour_ago: "Hace {{hour}} hora",
    hour_from_now: "Dentro de {{hour}} hora",
    hour_minute_ago: "Hace {{hour}} hora y {{minute}} minuto",
    hour_minute_from_now: "Dentro de {{hour}} hora y {{minute}} minuto",
    hour_minutes_ago: "Hace {{hour}} hora y {{minute}} minutos",
    hour_minutes_from_now: "Dentro de {{hour}} hora y {{minute}} minutos",
    hours_ago: "Hace {{hour}} horas",
    hours_from_now: "Dentro de {{hour}} horas",
    hours_minute_ago: "Hace {{hour}} horas y {{minute}} minuto",
    hours_minute_from_now: "Dentro de {{hour}} horas y {{minute}} minuto",
    hours_minutes_ago: "Hace {{hour}} horas y {{minute}} minutos",
    hours_minutes_from_now: "Dentro de {{hour}} horas y {{minute}} minutos",
    just_now: "Ahora mismo",
    kms: "km",
    kms_away: "A {{distance}} km",
    knots: "kts",
    last_current: "Última corriente",
    last_tide: "Última marea",
    loading: "Cargando...",
    low: "Baja",
    map: "Mapa",
    meters: "m",
    miles: "millas",
    miles_away: "A {{distance}} millas",
    minute_ago: "Hace {{minute}} minuto",
    minute_from_now: "Dentro de {{minute}} minuto",
    minutes_ago: "Hace {{minute}} minutos",
    minutes_from_now: "Dentro de {{minute}} minutos",
    moon: "Luna",
    moonrise_now: "Salida de la luna",
    moonrise_week: "Salida",
    moonset_now: "Puesta de la luna",
    moonset_week: "Puesta",
    nearby_currents: "Corrientes cercanas",
    nearby_tides: "Mareas cercanas",
    next_current: "Próxima corriente",
    next_tide: "Próxima marea",
    now: "Ahora",
    recent_currents: "Corrientes recientes",
    recent_tides: "Mareas recientes",
    recents: "Recientes",
    search: "Search",
    search_placeholder: "Buscar estaciones de mareas",
    settings: "Ajustes",
    show: "mostrar",
    slack: "Estoa",
    somethings_wrong: "Se ha producido un error",
    states: "Ciudades",
    sun: "Sol",
    sunrise_now: "Amanecer",
    sunrise_week: "Salida",
    sunset_now: "Puesta de sol",
    sunset_week: "Puesta",
    this_station: "Esta estación",
    tide_station: "Estación de mareas",
    tide_stations: "Estaciones de marea",
    tides: "Mareas",
    today: "Hoy",
    tomorrow: "Mañana",
    try_again: "Inténtelo de nuevo",
    turn_off_ads: "Desactivar publicidad durante tres días",
    unit: "Unidad",
    watch_rewarded_video: "Ver vídeo concedido",
    week: "Semana"
  }
};
