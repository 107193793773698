import React, { Component } from "react";
import I18n from "../../config/locales";

class CurrentStations extends Component {
  distanceAway(currentStation) {
    if (
      currentStation.distance_mi !== undefined &&
      currentStation.distance_mi !== null
    ) {
      return (
        <div className="text-muted">
          {currentStation.distance_mi} miles away
        </div>
      );
    }
  }

  render() {
    const { currentStations, nearby } = this.props;
    if (currentStations.length == 0) {
      return <div />;
    } else {
      const title = nearby
        ? I18n.t("nearby_currents")
        : I18n.t("current_stations");
      return (
        <div className="card bg-tides3 mb-2">
          <div className="card-body text-white">
            <h2 className="pl-3">{title}</h2>
            <div className="list-group list-group-flush">
              {currentStations.map(currentStation => (
                <a
                  href={"/current_stations/" + currentStation.id}
                  className="list-group-item list-group-item-action bg-tides2 text-white"
                  key={currentStation.id}
                >
                  <div>{currentStation.name}</div>
                  {this.distanceAway(currentStation)}
                </a>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CurrentStations;
