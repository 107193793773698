export default {
  translation: {
    app_name: "近隣の潮汐",
    app_version: "アプリのバージョン",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "属性",
    by_country: "国別",
    cities: "都道府県",
    clear_recent_currents: "最近の検潮所（潮汐流）をクリア",
    clear_recent_tides: "最近の検潮所（潮汐）をクリア",
    connection_error: "接続エラー",
    contact_support: "サポートに連絡",
    current_station: "検潮所（潮汐流）",
    current_stations: "検潮所（潮汐流）",
    currents: "潮汐流",
    distance: "距離",
    ebb: "干潮",
    favorite_currents: "お気に入りの潮汐流",
    favorite_tides: "お気に入りの潮汐",
    flood: "満潮",
    following_current: "さらに次の潮汐流",
    hide: "隠す	",
    high: "高",
    home: "ホーム",
    hour_ago: "{{hour}}時間前",
    hour_from_now: "今から{{hour}}時間後",
    hour_minute_ago: "{{hour}}時間{{minute}}分前",
    hour_minute_from_now: "今から{{hour}}時間{{minute}}分後",
    hour_minutes_ago: "{{hour}}時間{{minute}}分前",
    hour_minutes_from_now: "今から{{hour}}時間{{minute}}分後",
    hours_ago: "{{hour}}時間前",
    hours_from_now: "今から{{hour}}時間後",
    hours_minute_ago: "{{hour}}時間{{minute}}分前",
    hours_minute_from_now: "今から{{hour}}時間{{minute}}分後",
    hours_minutes_ago: "{{hour}}時間{{minute}}分前",
    hours_minutes_from_now: "今から{{hour}}時間{{minute}}分後",
    just_now: "今現在",
    kms: "km",
    kms_away: "{{distance}} km 遠方",
    knots: "ノット",
    last_current: "前回の潮汐流",
    last_tide: "前回の潮汐",
    loading: "ロード中...",
    low: "低",
    map: "マップ",
    meters: "m",
    miles: "マイル",
    miles_away: "{{distance}} マイル遠方",
    minute_ago: "{{minute}}分前",
    minute_from_now: "今から{{minute}}時間後",
    minutes_ago: "{{minute}}分前",
    minutes_from_now: "今から{{minute}}時間後",
    moon: "月",
    moonrise_now: "月の出",
    moonrise_week: "月の出",
    moonset_now: "月の入り",
    moonset_week: "月の入り",
    nearby_currents: "近くの潮汐流",
    nearby_tides: "近くの潮汐",
    next_current: "次の潮汐流",
    next_tide: "次の潮汐",
    now: "今すぐ",
    recent_currents: "最近の潮汐流",
    recent_tides: "最近の潮汐",
    recents: "最近",
    search: "検索",
    search_placeholder: "検潮所を検索",
    settings: "設定",
    show: "表示",
    slack: "停潮",
    somethings_wrong: "エラーが発生しました",
    states: "市町村",
    sun: "日",
    sunrise_now: "日の出",
    sunrise_week: "日の出",
    sunset_now: "日の入り",
    sunset_week: "日の入り",
    this_station: "この検潮所",
    tide_station: "検潮所",
    tide_stations: "検潮所",
    tides: "潮汐",
    today: "今日",
    tomorrow: "明日",
    try_again: "再度お試しください",
    turn_off_ads: "広告を 3 日間停止",
    unit: "単位",
    watch_rewarded_video: "報酬ビデオを視聴",
    week: "週"
  }
};
