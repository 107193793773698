import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import da from "./da";
import de from "./de";
import en from "./en";
import enGB from "./en-GB";
import es from "./es";
import fr from "./fr";
import it from "./it";
import ja from "./ja";
import ko from "./ko";
import nl from "./nl";
import nb from "./nb";
import pt from "./pt";
import zhHans from "./zh-Hans";
import zhHant from "./zh-Hant";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      da: da,
      de: de,
      en: en,
      "en-AU": enGB,
      "en-CA": enGB,
      "en-GB": enGB,
      "en-IN": enGB,
      es: es,
      fr: fr,
      it: it,
      ja: ja,
      ko: ko,
      nl: nl,
      nb: nb,
      nn: nb,
      no: nb,
      pt: pt,
      zh: zhHans,
      "zh-Hans": zhHans,
      "zh-Hant": zhHant,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
