export default {
  translation: {
    app_name: "Marés próximas a mim",
    app_version: "Versão do aplicativo",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Atribuição",
    by_country: "Por país",
    cities: "Estados",
    clear_recent_currents: "Limpar estações de correntes recentes",
    clear_recent_tides: "Limpar estações de marés recentes",
    connection_error: "Erro de conexão",
    contact_support: "Fale com o suporte",
    current_station: "Estação de correntes",
    current_stations: "Estações de correntes",
    currents: "Correntes",
    distance: "Distância",
    ebb: "Vazante",
    favorite_currents: "Correntes favoritas",
    favorite_tides: "Marés favoritas",
    flood: "Enchente",
    following_current: "Corrente seguinte",
    hide: "ocultar",
    high: "Alta",
    home: "Início",
    hour_ago: "{{hour}} hora atrás",
    hour_from_now: "Daqui {{hour}} hora",
    hour_minute_ago: "{{hour}} hora e {{minute}} minuto atrás",
    hour_minute_from_now: "Daqui {{hour}} hora e {{minute}} minuto",
    hour_minutes_ago: "{{hour}} hora e {{minute}} minutos atrás",
    hour_minutes_from_now: "Daqui {{hour}} hora e {{minute}} minutos",
    hours_ago: "{{hour}} horas atrás",
    hours_from_now: "Daqui {{hour}} horas",
    hours_minute_ago: "{{hour}} horas e {{minute}} minuto atrás",
    hours_minute_from_now: "Daqui {{hour}} horas e {{minute}} minuto",
    hours_minutes_ago: "{{hour}} horas e {{minute}} minutos atrás",
    hours_minutes_from_now: "Daqui {{hour}} horas e {{minute}} minutos",
    just_now: "Agora",
    kms: "km",
    kms_away: "A {{distance}} km de distância",
    knots: "nós",
    last_current: "Última corrente",
    last_tide: "Última maré",
    loading: "Carregando...",
    low: "Baixa",
    map: "Mapa",
    meters: "m",
    miles: "milhas",
    miles_away: "A {{distance}} milhas de distância",
    minute_ago: "{{minute}} minuto atrás",
    minute_from_now: "Daqui {{minute}} minuto",
    minutes_ago: "{{minute}} minutos atrás",
    minutes_from_now: "Daqui {{minute}} minutos",
    moon: "Lua",
    moonrise_now: "Nascer da lua",
    moonrise_week: "Nascer",
    moonset_now: "Pôr da lua",
    moonset_week: "Pôr",
    nearby_currents: "Correntes nas proximidades",
    nearby_tides: "Marés nas proximidades",
    next_current: "Próxima corrente",
    next_tide: "Próxima maré",
    now: "Agora",
    recent_currents: "Correntes recentes",
    recent_tides: "Marés recentes",
    recents: "Recentes",
    search: "Pesquisa",
    search_placeholder: "Pesquisar estações de marés",
    settings: "Configurações",
    show: "exibir",
    slack: "Estofo",
    somethings_wrong: "Houve um erro.",
    states: "Cidades",
    sun: "Sol",
    sunrise_now: "Nascer do sol",
    sunrise_week: "Nascer",
    sunset_now: "Pôr do sol",
    sunset_week: "Pôr",
    this_station: "Esta estação",
    tide_station: "Estação de marés",
    tide_stations: "Estações de marés",
    tides: "Marés",
    today: "Hoje",
    tomorrow: "Amanhã",
    try_again: "Tente novamente",
    turn_off_ads: "Desativar anúncios por três dias",
    unit: "Unidade",
    watch_rewarded_video: "Assistir vídeo de recompensa",
    week: "Semana"
  }
};
