import React from "react";
import I18n from "../../config/locales";

const States = ({ states, stateTerm }) => {
  if (states === undefined || states.length == 0) {
    return <div />;
  } else {
    return (
      <div className="card bg-tides3 mb-2">
        <div className="card-body text-white">
          <h2 className="pl-3" style={{ textTransform: "capitalize" }}>
            {stateTerm}
          </h2>
          <ul className="list-group list-group-flush">
            {states.map(state => (
              <a
                href={"/states/" + state.id}
                className="list-group-item list-group-item-action bg-tides2 text-white"
                key={state.id}
              >
                {state.name}
              </a>
            ))}
          </ul>
        </div>
      </div>
    );
  }
};

export default States;
