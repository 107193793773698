import React, { Component } from "react";
import I18n from "../../../config/locales";
import {
  dateDisplay,
  microformatTime,
  timeDisplay,
  timezoneIfDifferent,
} from "../../../config/datetime";
import Flood from "images/currents/flood.png";
import Slack from "images/currents/slack.png";

class Week extends Component {
  currentType(currentEvent) {
    if (currentEvent.max_velocity === null) {
      return I18n.t("slack");
    } else if (currentEvent.max_velocity < 0) {
      return I18n.t("ebb");
    } else {
      return I18n.t("flood");
    }
  }

  currentSpeed(currentEvent) {
    if (currentEvent.max_velocity === null) {
      return <img className="mr-3" src={Slack} />;
    } else {
      return currentEvent.max_velocity + " " + I18n.t("knots");
    }
  }

  currentEvent(currentEvent, last = false) {
    const { currentStation } = this.props;
    return (
      <tr
        key={currentEvent.date_time}
        itemScope="itemScope"
        itemType="http://schema.org/Event"
      >
        <td
          className="border-tides5 text-left"
          style={{ width: "33.33%" }}
          itemProp="name"
        >
          {this.currentType(currentEvent)}
        </td>
        <td className="border-tides5 text-center" style={{ width: "33.33%" }}>
          <time
            itemProp="startDate"
            dateTime={microformatTime(
              currentEvent.date_time,
              currentStation.timezone
            )}
          >
            {timeDisplay(currentEvent.date_time)}
          </time>
          <div
            itemProp="location"
            itemScope="itemScope"
            itemType="http://schema.org/Place"
          >
            <meta itemProp="name" content={currentEvent.name} />
            <div
              itemProp="geo"
              itemScope="itemScope"
              itemType="http://schema.org/GeoCoordinates"
            >
              <meta itemProp="latitude" content={currentEvent.lat} />
              <meta itemProp="longitude" content={currentEvent.lng} />
            </div>
          </div>
        </td>
        <td
          className="border-tides5 text-right"
          style={{ width: "33.33%" }}
          itemProp="description"
        >
          {this.currentSpeed(currentEvent)}
        </td>
      </tr>
    );
  }

  currentEvents(date) {
    const { currentStation } = this.props;
    return (
      <div className="bg-tides1 px-3 pt-1">
        <table className="table table-dark bg-tides1 table-sm mb-0">
          <thead className="table-borderless">
            <tr>
              <td colSpan="3" className="text-center">
                {I18n.t("currents").toUpperCase()}
                {timezoneIfDifferent(currentStation.timezone, " (", ")")}
              </td>
            </tr>
          </thead>
          <tbody>
            {currentStation.next_seven_days[
              date
            ].currents.map((currentEvent, index) =>
              index + 1 != currentStation.next_seven_days[date].currents.length
                ? this.currentEvent(currentEvent)
                : this.currentEvent(currentEvent, true)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  dayForecast(date) {
    return (
      <div className="card bg-tides3 my-2" key={date}>
        <div className="card-body text-center text-white">
          <div className="mb-2">
            <time className="card-title h5">{dateDisplay(date)}</time>
          </div>
          {this.currentEvents(date)}
        </div>
      </div>
    );
  }

  weekForecast() {
    const { currentStation } = this.props;
    var dateKeyArray = [];
    for (var date in currentStation.next_seven_days) {
      dateKeyArray.push(date);
    }
    return dateKeyArray.map((date) => this.dayForecast(date));
  }

  render() {
    const { currentStation } = this.props;
    return (
      <div>
        <h2 className="text-center mt-3">{I18n.t("week")}</h2>
        {this.weekForecast()}
      </div>
    );
  }
}

export default Week;
