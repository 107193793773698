import React, { useEffect, useState } from "react";
import I18n from "../../../config/locales";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Now from "./Now";
import Week from "./Week";
import Map from "./Map";
import Breadcrumbs from "../../home/Breadcrumbs";
import MobileStoreButtons from "../../home/MobileStoreButtons";

export default function TideStation() {
  const language_code = window.navigator.userLanguage || window.navigator.language || "en";
  let { id } = useParams();
  let apiUrl = window.location.origin + "/api/v2" + "/stations/tide/" + id + "?language=" + language_code;
  const [tideStation, loading, hasError] = useFetch(apiUrl);
  return (
    <>
      {
        tideStation == null ? <div/> : <div>{renderTideStation(tideStation)}</div>
      }
    </>
  );
}

function renderTideStation(tideStation) {
  const title = tideStation.name + " " + I18n.t("tides");
  return (
    <div className="container">
      <Helmet>
        <title>
          {title} - {I18n.t("app_name")}
        </title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <div className="row">
        <div className="col-md d-flex justify-content-center my-3">
          <h1>{title}</h1>
        </div>
      </div>
      <Breadcrumbs results={tideStation} />
      <div className="row">
        <div className="col-md">
          <Now tideStation={tideStation} />
          <Week tideStation={tideStation} />
        </div>
        <div className="col-md">
          <Map tideStation={tideStation} />
          <MobileStoreButtons />
        </div>
      </div>
      {attribution(tideStation.attribution)}
    </div>
  );
}

function attribution(str) {
  if (str !== null) {
    return (
      <div className="row">
      <div className="col" />
      <div className="col-6 text-center mb-2">
      <small>{str}</small>
      </div>
      <div className="col" />
      </div>
      );
  } else {
    return <div />;
  }
}

function useFetch(url, opts) {
  const [tideStation, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setLoading(true)
    fetch(url, opts)
      .then((res) => res.json())
      .then((data) => {
        setResponse(data)
        setLoading(false)
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      })
    }, [ url ]);
  return [ tideStation, loading, hasError ];
}
