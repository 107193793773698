import React, { Component } from "react";
import AppNavigation from "./AppNavigation";

class App extends Component {
  render() {
    return <AppNavigation />;
  }
}

export default App;
