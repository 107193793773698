import React, { useEffect, useState } from "react";
import { Map as Maplibre, AttributionControl, Layer, Popup, Source } from "react-map-gl/maplibre";
import I18n from "../../../config/locales";

const styleURL = "https://d1nezfegjr0s5p.cloudfront.net/tiles/esri-style.json.gz";
const stationsURL = "https://d1nezfegjr0s5p.cloudfront.net/tiles/source.json.gz";

export default function Map({ currentStation }) {
  const [viewport, setViewport] = useState({
    latitude: currentStation.lat,
    longitude: currentStation.lng,
    zoom: 12
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupStation, setPopupStation] = useState(null);

  useEffect(() => {
    if (popupStation) {
      setShowPopup(true);
    }
  }, [popupStation]);

  const currentLayerStyle = {
    id: "currentStationsInactiveLayer",
    type: "symbol",
    "source-layer": "stations",
    filter: [
      "all",
      ["==", "station_type", "current"],
      ["!=", "id", currentStation.id],
    ],
    layout: {
      "icon-image": "currentStationInactive",
      "icon-size": 0.75,
    },
    minzoom: 3,
    maxzoom: 20,
    beforeId: "currentStationsActiveLayer",
  };

  const tideLayerStyle = {
    id: "tideStationsInactiveLayer",
    type: "symbol",
    "source-layer": "stations",
    filter: ["==", "station_type", "tide"],
    layout: {
      "icon-image": "tideStationInactive",
      "icon-size": 0.75,
    },
    minzoom: 3,
    maxzoom: 20,
    beforeId: "currentStationsInactiveLayer",
  };

  const activeCurrentLayerStyle = {
    id: "currentStationsActiveLayer",
    type: "symbol",
    "source-layer": "stations",
    filter: [
      "all",
      ["==", "station_type", "current"],
      ["==", "id", currentStation.id],
    ],
    layout: {
      "icon-image": "currentStationActive",
      "icon-size": 0.75,
    },
    minzoom: 3,
    maxzoom: 20,
  };

  return (
    <Maplibre
      {...viewport}
      onMove={event => setViewport(event.viewState)}
      style={{
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#0a405a",
        width: '100%',
        height: '60vh'
      }}
      mapStyle={styleURL}
      attributionControl={false}
      onClick={(e) => {
        if (e.features.length > 0 && e.features[0].source == "stations") {
          let station = e.features[0].properties;
          station.lat = e.lngLat.lat;
          station.lng = e.lngLat.lng;
          setPopupStation(station);
        }
      }}
      interactiveLayerIds={[
        "currentStationsActiveLayer",
        "tideStationsInactiveLayer",
        "currentStationsInactiveLayer"
      ]}
    >
      <Source
        id="stations"
        url={stationsURL}
        type="vector"
        minzoom={0}
        maxzoom={10}
      >
        <Layer {...activeCurrentLayerStyle} />
        <Layer {...tideLayerStyle} />
        <Layer {...currentLayerStyle} />
      </Source>
      {showPopup && (
        <Popup
          longitude={popupStation.lng}
          latitude={popupStation.lat}
          className="stationPopup"
          onClose={() => setShowPopup(false)}>
            <div>
              <p>
                <strong>{popupStation.name}</strong>
                <br />
                <a href={`/${popupStation.station_type}_stations/${popupStation.id}`}>
                  Visit {popupStation.station_type} station
                </a>
              </p>
            </div>
        </Popup>
      )}
      <AttributionControl
        customAttribution={I18n.t("attribution_body")}
        compact={false}
      />
    </Maplibre>
  );
}