import React, { useEffect, useRef, useState } from "react";
import { Map as Maplibre, AttributionControl, Layer, Popup, Source } from "react-map-gl/maplibre";
import { WebMercatorViewport } from '@math.gl/web-mercator';
import I18n from "../../config/locales";

const styleURL = "https://d1nezfegjr0s5p.cloudfront.net/tiles/esri-style.json.gz";
const stationsURL = "https://d1nezfegjr0s5p.cloudfront.net/tiles/source.json.gz";

export default function Map({ results }) {
  const [viewport, setViewport] = useState({
    // bug: map won't update to user location if zoom 0, zoom 1 works
    // so default lat/lng is in the middle of the atlantic ocean
    latitude: 30,
    longitude: -40,
    zoom: 1
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupStation, setPopupStation] = useState(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    // show popup only when loaded
    if (popupStation) {
      setShowPopup(true);
    }

    // bounds indicate country, state, or city
    if ('max_x' in results && 'max_y' in results && 'min_x' in results && 'min_y' in results) {
      const width = mapContainerRef.current.offsetWidth;
      const height = mapContainerRef.current.offsetHeight;
      const { longitude, latitude, zoom } = new WebMercatorViewport({
        width,
        height
      })
      .fitBounds(
        [
          [parseFloat(results.min_x), parseFloat(results.min_y)],
          [parseFloat(results.max_x), parseFloat(results.max_y)]
        ],
        { padding: 30 }
      );
      setViewport({
        longitude,
        latitude,
        zoom,
        width,
        height
      });
    }
    // or center on user location
    else if ('userLat' in results && 'userLng' in results) {
      setViewport({
        longitude: results.userLng,
        latitude: results.userLat,
        zoom: 11,
      });
    }
  }, [popupStation, results]);

  const currentLayerStyle = {
    id: "currentStationsInactiveLayer",
    type: "symbol",
    "source-layer": "stations",
    filter: ["==", "station_type", "current"],
    layout: {
      "icon-image": "currentStationInactive",
      "icon-size": 0.75,
    },
    minzoom: 3,
    maxzoom: 20
  };

  const tideLayerStyle = {
    id: "tideStationsInactiveLayer",
    type: "symbol",
    "source-layer": "stations",
    filter: ["==", "station_type", "tide"],
    layout: {
      "icon-image": "tideStationInactive",
      "icon-size": 0.75,
    },
    minzoom: 3,
    maxzoom: 20
  };

  return (
    <div ref={mapContainerRef}>
      <Maplibre
        {...viewport}
        onMove={event => setViewport(event.viewState)}
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#0a405a",
          width: '100%',
          height: '60vh'
        }}
        mapStyle={styleURL}
        attributionControl={false}
        onClick={(e) => {
          if (e.features.length > 0 && e.features[0].source == "stations") {
            let station = e.features[0].properties;
            station.lat = e.lngLat.lat;
            station.lng = e.lngLat.lng;
            setPopupStation(station);
          }
        }}
        interactiveLayerIds={[
          "tideStationsInactiveLayer",
          "currentStationsInactiveLayer"
        ]}
      >
        <Source
          id="stations"
          url={stationsURL}
          type="vector"
          minzoom={0}
          maxzoom={10}
        >
          <Layer {...tideLayerStyle} />
          <Layer {...currentLayerStyle} />
        </Source>
        {showPopup && (
          <Popup
            longitude={popupStation.lng}
            latitude={popupStation.lat}
            className="stationPopup"
            onClose={() => setShowPopup(false)}>
              <div>
                <p>
                  <strong>{popupStation.name}</strong>
                  <br />
                  <a href={`/${popupStation.station_type}_stations/${popupStation.id}`}>
                    Visit {popupStation.station_type} station
                  </a>
                </p>
              </div>
          </Popup>
        )}
        <AttributionControl
          customAttribution={I18n.t("attribution_body")}
          compact={false}
        />
      </Maplibre>
    </div>
  );
}