import React from "react";
import I18n from "../../config/locales";

const Cities = ({ cities }) => {
  if (cities === undefined || cities.length == 0) {
    return <div />;
  } else {
    return (
      <div className="card bg-tides3 mb-2">
        <div className="card-body text-white">
          <h2 className="pl-3">{I18n.t("cities")}</h2>
          <ul className="list-group list-group-flush">
            {cities.map(city => (
              <a
                href={"/cities/" + city.id}
                className="list-group-item list-group-item-action bg-tides2 text-white"
                key={city.id}
              >
                {city.name}
              </a>
            ))}
          </ul>
        </div>
      </div>
    );
  }
};

export default Cities;
