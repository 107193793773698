import React, { Component } from "react";
import I18n from "../../../config/locales";
import {
  dateDisplay,
  microformatTime,
  timeDisplay,
  timezoneIfDifferent,
} from "../../../config/datetime";
import UpArrow from "images/tides/up.png";
import DownArrow from "images/tides/down.png";
import Tides from "images/tides/tide.png";
import Sun from "images/tides/sun.png";
import Moon from "images/tides/moon.png";

class Week extends Component {
  tidalEvent(tidalEvent, last = false) {
    const { tideStation } = this.props;
    const metric = tideStation.country_id !== 1;
    return (
      <tr
        key={tidalEvent.timestamp}
        itemScope="itemScope"
        itemType="http://schema.org/Event"
      >
        <td
          className="border-tides5 text-left"
          style={{ width: "33.33%" }}
          itemProp="name"
        >
          {tidalEvent.high ? (
            <img className="mr-2 mb-1" src={UpArrow} />
          ) : (
            <img className="mr-2 mb-1" src={DownArrow} />
          )}
          {tidalEvent.high ? I18n.t("high") : I18n.t("low")}
        </td>
        <td className="border-tides5 text-center" style={{ width: "33.33%" }}>
          <time
            itemProp="startDate"
            dateTime={microformatTime(
              tidalEvent.timestamp,
              tideStation.timezone
            )}
          >
            {timeDisplay(tidalEvent.timestamp)}
          </time>
          <div
            itemProp="location"
            itemScope="itemScope"
            itemType="http://schema.org/Place"
          >
            <meta itemProp="name" content={tideStation.name} />
            <div
              itemProp="geo"
              itemScope="itemScope"
              itemType="http://schema.org/GeoCoordinates"
            >
              <meta itemProp="latitude" content={tideStation.lat} />
              <meta itemProp="longitude" content={tideStation.lng} />
            </div>
          </div>
        </td>
        <td
          className="border-tides5 text-right"
          style={{ width: "33.33%" }}
          itemProp="description"
        >
          {metric
            ? (tidalEvent.height_cm / 100).toFixed(2) + I18n.t("meters")
            : tidalEvent.height_ft.toFixed(1) + "'"}
        </td>
      </tr>
    );
  }

  tidalEvents(date) {
    try {
      const { tideStation } = this.props;
      return (
        <div className="bg-tides1 px-3 pt-1">
          <table className="table table-dark bg-tides1 table-sm mb-0">
            <thead className="table-borderless">
              <tr>
                <td colSpan="3" className="text-center">
                  <img className="mr-2 mb-1" src={Tides} />
                  {I18n.t("tides").toUpperCase()}
                  {timezoneIfDifferent(tideStation.timezone, " (", ")")}
                </td>
              </tr>
            </thead>
            <tbody>
              {tideStation.next_seven_days[
                date
              ].tidal_events.map((tidalEvent, index) =>
                index + 1 !=
                tideStation.next_seven_days[date].tidal_events.length
                  ? this.tidalEvent(tidalEvent)
                  : this.tidalEvent(tidalEvent, true)
              )}
            </tbody>
          </table>
        </div>
      );
    } catch (err) {
      // some UKHO stations have celestial with no tides
      return <div />;
    }
  }

  solarEvent(solarEvent, last = false) {
    const { tideStation } = this.props;
    return (
      <tr
        key={solarEvent.timestamp}
        itemScope="itemScope"
        itemType="http://schema.org/Event"
      >
        <td
          className="border-tides5 text-left"
          style={{ width: "33.33%" }}
          itemProp="name"
        >
          {solarEvent.rise ? (
            <img className="mr-2 mb-1" src={UpArrow} />
          ) : (
            <img className="mr-2 mb-1" src={DownArrow} />
          )}
          {solarEvent.rise ? I18n.t("sunrise_week") : I18n.t("sunset_week")}
        </td>
        <td className="border-tides5 text-center" style={{ width: "33.33%" }}>
          <time
            itemProp="startDate"
            dateTime={microformatTime(
              solarEvent.timestamp,
              tideStation.timezone
            )}
          >
            {timeDisplay(solarEvent.timestamp)}
          </time>
          <div
            itemProp="location"
            itemScope="itemScope"
            itemType="http://schema.org/Place"
          >
            <meta itemProp="name" content={tideStation.name} />
            <div
              itemProp="geo"
              itemScope="itemScope"
              itemType="http://schema.org/GeoCoordinates"
            >
              <meta itemProp="latitude" content={tideStation.lat} />
              <meta itemProp="longitude" content={tideStation.lng} />
            </div>
          </div>
        </td>
        <td className="border-tides5 text-right" style={{ width: "33.33%" }}>
          &nbsp;
        </td>
      </tr>
    );
  }

  solarEvents(date) {
    const { tideStation } = this.props;
    return (
      <div className="bg-tides2 px-3">
        <table className="table table-dark bg-tides2 my-0 py-1 table-sm">
          <thead className="table-borderless">
            <tr>
              <td colSpan="3" className="text-center pt-2">
                <img className="mr-2 mb-1" src={Sun} />
                {I18n.t("sun").toUpperCase()}
                {timezoneIfDifferent(tideStation.timezone, " (", ")")}
              </td>
            </tr>
          </thead>
          <tbody>
            {tideStation.next_seven_days[
              date
            ].solar_events.map((solarEvent, index) =>
              index + 1 != tideStation.next_seven_days[date].solar_events.length
                ? this.solarEvent(solarEvent)
                : this.solarEvent(solarEvent, true)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  lunarEvent(lunarEvent, last = false) {
    const { tideStation } = this.props;
    return (
      <tr
        key={lunarEvent.timestamp}
        itemScope="itemScope"
        itemType="http://schema.org/Event"
      >
        <td
          className="border-tides5 text-left"
          style={{ width: "33.33%" }}
          itemProp="name"
        >
          {lunarEvent.rise ? (
            <img className="mr-2 mb-1" src={UpArrow} />
          ) : (
            <img className="mr-2 mb-1" src={DownArrow} />
          )}
          {lunarEvent.rise ? I18n.t("moonrise_week") : I18n.t("moonset_week")}
        </td>
        <td className="border-tides5 text-center" style={{ width: "33.33%" }}>
          <time
            itemProp="startDate"
            dateTime={microformatTime(
              lunarEvent.timestamp,
              tideStation.timezone
            )}
          >
            {timeDisplay(lunarEvent.timestamp)}
          </time>
          <div
            itemProp="location"
            itemScope="itemScope"
            itemType="http://schema.org/Place"
          >
            <meta itemProp="name" content={tideStation.name} />
            <div
              itemProp="geo"
              itemScope="itemScope"
              itemType="http://schema.org/GeoCoordinates"
            >
              <meta itemProp="latitude" content={tideStation.lat} />
              <meta itemProp="longitude" content={tideStation.lng} />
            </div>
          </div>
        </td>
        <td
          className="border-tides5 text-right"
          style={{ width: "33.33%" }}
          itemProp="description"
        >
          {(lunarEvent.percentage * 100).toFixed(0)}%
        </td>
      </tr>
    );
  }

  lunarEvents(date) {
    const { tideStation } = this.props;
    return (
      <div className="bg-tides4 px-3">
        <table className="table table-dark bg-tides4 mt-0 py-1 table-sm">
          <thead className="table-borderless">
            <tr>
              <td colSpan="3" className="text-center pt-2">
                <img className="mr-2 mb-1" src={Moon} />
                {I18n.t("moon").toUpperCase()}
                {timezoneIfDifferent(tideStation.timezone, " (", ")")}
              </td>
            </tr>
          </thead>
          <tbody>
            {tideStation.next_seven_days[
              date
            ].lunar_events.map((lunarEvent, index) =>
              index + 1 != tideStation.next_seven_days[date].lunar_events.length
                ? this.lunarEvent(lunarEvent)
                : this.lunarEvent(lunarEvent, true)
            )}
          </tbody>
        </table>
      </div>
    );
  }

  dayForecast(date) {
    return (
      <div className="card bg-tides3 my-2" key={date}>
        <div className="card-body text-center text-white">
          <div className="mb-2">
            <time className="card-title h5">{dateDisplay(date)}</time>
          </div>
          {this.tidalEvents(date)}
          {this.solarEvents(date)}
          {this.lunarEvents(date)}
        </div>
      </div>
    );
  }

  weekForecast() {
    const { tideStation } = this.props;
    var dateKeyArray = [];
    for (var date in tideStation.next_seven_days) {
      dateKeyArray.push(date);
    }
    return dateKeyArray.map((date) => this.dayForecast(date));
  }

  render() {
    const { tideStation } = this.props;
    return (
      <div>
        <h2 className="text-center mt-3">{I18n.t("week")}</h2>
        {this.weekForecast()}
      </div>
    );
  }
}

export default Week;
