import I18n from "./locales";
import moment from "moment";
import momentTimezone from "moment-timezone";
const languageCode =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  "en-US";
moment.locale(languageCode);
const deviceTimezone = momentTimezone.tz.guess(true);

export function timezoneIfDifferent(
  stationTimezone,
  preStr = "",
  postStr = ""
) {
  if (stationTimezone != deviceTimezone) {
    return (
      preStr +
      momentTimezone()
        .tz(deviceTimezone)
        .format("z") +
      postStr
    );
  } else {
    return "";
  }
}

export function timeDisplay(timestamp) {
  return moment(timestamp).format("LT");
}

export function dateDisplay(date) {
  var momentDate = moment(date);
  var momentNow = moment().startOf("day");
  var oneDay = 1000 * 60 * 60 * 24;
  if (momentDate - momentNow < oneDay) {
    return I18n.t("today");
  } else if (momentDate - momentNow < oneDay * 2) {
    return I18n.t("tomorrow");
  } else {
    return moment(date).format("dddd - ll");
  }
}

export function microformatTime(timestamp, timezone) {
  return momentTimezone(timestamp)
    .tz(timezone)
    .format("YYYY-MM-DD HH:mmZZ");
}

export function relativeTimeFromNow(toTime) {
  var tideTime = new Date(toTime);
  var now = new Date();
  if (isNaN(tideTime) || isNaN(now)) {
    return "";
  }
  var secondsDiff = (tideTime.getTime() - now.getTime()) / 1000;
  var minutesDiff = secondsDiff / 60;
  var hoursDiff = minutesDiff / 60;
  var numberHours = Math.floor(Math.abs(hoursDiff));
  var numberMinutes = Math.floor(Math.abs(minutesDiff)) - numberHours * 60;
  var hoursSingular = hoursDiff == 1 || hoursDiff == -1;
  var minutesSingular = numberMinutes == 1 || numberMinutes == -1;
  var hoursOnly = numberHours >= 1 && numberMinutes <= 0;
  var minutesOnly = numberMinutes >= 1 && numberHours <= 0;
  if (Math.abs(secondsDiff) > 86400) {
    return I18n.t("somethings_wrong");
  } else if (Math.abs(secondsDiff) < 60) {
    return I18n.t("just_now");
  }
  // ago / from now
  else {
    // ago
    if (secondsDiff < 0) {
      if (minutesOnly) {
        if (minutesSingular) {
          return I18n.t("minute_ago", { minute: numberMinutes });
        } else {
          return I18n.t("minutes_ago", { minute: numberMinutes });
        }
      } else if (hoursOnly) {
        if (hoursSingular) {
          return I18n.t("hour_ago", { hour: numberHours });
        } else {
          return I18n.t("hours_ago", { hour: numberHours });
        }
      } else {
        if (hoursSingular && minutesSingular) {
          return I18n.t("hour_minute_ago", {
            hour: numberHours,
            minute: numberMinutes
          });
        } else if (hoursSingular && !minutesSingular) {
          return I18n.t("hour_minutes_ago", {
            hour: numberHours,
            minute: numberMinutes
          });
        } else if (!hoursSingular && minutesSingular) {
          return I18n.t("hours_minute_ago", {
            hour: numberHours,
            minute: numberMinutes
          });
        } else {
          return I18n.t("hours_minutes_ago", {
            hour: numberHours,
            minute: numberMinutes
          });
        }
      }
    }
    // from now
    else {
      if (minutesOnly) {
        if (minutesSingular) {
          return I18n.t("minute_from_now", { minute: numberMinutes });
        } else {
          return I18n.t("minutes_from_now", { minute: numberMinutes });
        }
      } else if (hoursOnly) {
        if (hoursSingular) {
          return I18n.t("hour_from_now", { hour: numberHours });
        } else {
          return I18n.t("hours_from_now", { hour: numberHours });
        }
      } else {
        if (hoursSingular && minutesSingular) {
          return I18n.t("hour_minute_from_now", {
            hour: numberHours,
            minute: numberMinutes
          });
        } else if (hoursSingular && !minutesSingular) {
          return I18n.t("hour_minutes_from_now", {
            hour: numberHours,
            minute: numberMinutes
          });
        } else if (!hoursSingular && minutesSingular) {
          return I18n.t("hours_minute_from_now", {
            hour: numberHours,
            minute: numberMinutes
          });
        } else {
          return I18n.t("hours_minutes_from_now", {
            hour: numberHours,
            minute: numberMinutes
          });
        }
      }
    }
  }
}
