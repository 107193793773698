export default {
  translation: {
    app_name: "Maree nei dintorni",
    app_version: "Versione app",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Attribuzione",
    by_country: "Per paese",
    cities: "Stati",
    clear_recent_currents: "Cancella stazioni di corrente recenti",
    clear_recent_tides: "Cancella stazioni mareografiche recenti",
    connection_error: "Errore di connessione",
    contact_support: "Contatta l'assistenza",
    current_station: "Stazione di corrente",
    current_stations: "Stazioni di corrente",
    currents: "Correnti",
    distance: "Distanza",
    ebb: "Bassa marea",
    favorite_currents: "Correnti preferite",
    favorite_tides: "Maree preferite",
    flood: "Flusso",
    following_current: "Corrente successiva",
    hide: "nascondi",
    high: "Alta",
    home: "Home",
    hour_ago: "{{minute}} ora fa",
    hour_from_now: "{{hour}} ora da adesso",
    hour_minute_ago: "{{hour}} ora, {{minute}} minuto fa",
    hour_minute_from_now: "{{hour}} ora, {{minute}} minuto da adesso",
    hour_minutes_ago: "{{hour}} ora, {{minute}} minuti fa",
    hour_minutes_from_now: "{{hour}} ora, {{minute}} minuti da adesso",
    hours_ago: "{{minute}} ore fa",
    hours_from_now: "{{hour}} ore da adesso",
    hours_minute_ago: "{{hour}} ore, {{minute}} minuto fa",
    hours_minute_from_now: "{{hour}} ore, {{minute}} minuto da adesso",
    hours_minutes_ago: "{{hour}} ore, {{minute}} minuti fa",
    hours_minutes_from_now: "{{hour}} ore, {{minute}} minuti da adesso",
    just_now: "Adesso",
    kms: "Km",
    kms_away: "A {{distance}} Km",
    knots: "kn",
    last_current: "Corrente precedente",
    last_tide: "Marea precedente",
    loading: "Caricamento...",
    low: "Bassa",
    map: "Mappa",
    meters: "m",
    miles: "miglia",
    miles_away: "A {{distance}} miglia",
    minute_ago: "{{minute}} minuto fa",
    minute_from_now: "{{minute}} minuto da adesso",
    minutes_ago: "{{minute}} minuti fa",
    minutes_from_now: "{{minute}} minuti da adesso",
    moon: "Luna",
    moonrise_now: "Sorgere della luna",
    moonrise_week: "Sorgere",
    moonset_now: "Tramonto della luna",
    moonset_week: "Tramonto",
    nearby_currents: "Correnti nei dintorni",
    nearby_tides: "Maree nei dintorni",
    next_current: "Prossima corrente",
    next_tide: "Prossima marea",
    now: "Adesso",
    recent_currents: "Correnti recenti",
    recent_tides: "Maree recenti",
    recents: "Recenti",
    search: "Cerca",
    search_placeholder: "Cerca stazioni mareografiche",
    settings: "Impostazioni",
    show: "mostra",
    slack: "Stanca",
    somethings_wrong: "Si è verificato un errore",
    states: "Città",
    sun: "Sole",
    sunrise_now: "Alba",
    sunrise_week: "Sorgere",
    sunset_now: "Tramonto",
    sunset_week: "Tramonto",
    this_station: "Questa stazione",
    tide_station: "Stazione mareografica",
    tide_stations: "Stazioni mareografiche",
    tides: "Maree",
    today: "Oggi",
    tomorrow: "Domani",
    try_again: "Riprova",
    turn_off_ads: "Disattiva pubblicità per tre giorni",
    unit: "Unità",
    watch_rewarded_video: "Guarda video di ricompensa",
    week: "Settimana"
  }
};
