import React, { useEffect, useState } from "react";
import I18n from "../../../config/locales";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Now from "./Now";
import Week from "./Week";
import Map from "./Map";
import Breadcrumbs from "../../home/Breadcrumbs";
import MobileStoreButtons from "../../home/MobileStoreButtons";

export default function CurrentStation() {
  const language_code = window.navigator.userLanguage || window.navigator.language || "en";
  let { id } = useParams();
  let apiUrl = window.location.origin + "/api/v2" + "/stations/current/" + id + "?language=" + language_code;
  const [currentStation, loading, hasError] = useFetch(apiUrl);
  return (
    <>
      {
        currentStation == null ? <div/> : <div>{renderCurrentStation(currentStation)}</div>
      }
    </>);
}

function renderCurrentStation(currentStation) {
  const title = currentStation.name + " " + I18n.t("currents");
  return (
    <div className="container">
      <Helmet>
        <title>
          {title} - {I18n.t("app_name")}
        </title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <div className="row">
        <div className="col-md d-flex justify-content-center my-3">
          <h1>{title}</h1>
        </div>
      </div>
      <Breadcrumbs results={currentStation} />
      <div className="row mb-2">
        <div className="col-md">
          <Now currentStation={currentStation} />
          <Week currentStation={currentStation} />
        </div>
        <div className="col-md">
          <Map currentStation={currentStation} />
          <MobileStoreButtons />
        </div>
      </div>
    </div>
  );
}

function useFetch(url, opts) {
  const [currentStation, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setLoading(true)
    fetch(url, opts)
      .then((res) => res.json())
      .then((data) => {
        setResponse(data)
        setLoading(false)
      })
      .catch(() => {
        setHasError(true)
        setLoading(false)
      })
    }, [ url ]);
  return [ currentStation, loading, hasError ];
}