export default {
  translation: {
    app_name: "潮汐在身边",
    app_version: "应用版本",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "属性",
    by_country: "按国家/地区",
    cities: "城市",
    clear_recent_currents: "清空最近的潮流站",
    clear_recent_tides: "清空最近的潮汐站",
    connection_error: "连接错误",
    contact_support: "联系支持部门",
    current_station: "潮流站",
    current_stations: "潮流站",
    currents: "近的潮流",
    distance: "距离",
    ebb: "潮退",
    favorite_currents: "喜爱的潮流",
    favorite_tides: "喜爱的潮汐",
    flood: "顺流",
    following_current: "顺流",
    hide: "隐藏",
    high: "高位",
    home: "主页",
    hour_ago: "{{hour}} 小时前",
    hour_from_now: "{{hour}} 小时后",
    hour_minute_ago: "{{hour}} 小时 {{minute}} 分钟前",
    hour_minute_from_now: "{{hour}} 小时 {{minute}} 分钟后",
    hour_minutes_ago: "{{hour}} 小时 {{minute}} 分钟前",
    hour_minutes_from_now: "{{hour}} 小时 {{minute}} 分钟后",
    hours_ago: "{{hour}} 小时前",
    hours_from_now: "{{hour}} 小时后",
    hours_minute_ago: "{{hour}} 小时 {{minute}} 分钟前",
    hours_minute_from_now: "{{hour}} 小时 {{minute}} 分钟后",
    hours_minutes_ago: "{{hour}} 小时 {{minute}} 分钟前",
    hours_minutes_from_now: "{{hour}} 小时 {{minute}} 分钟后",
    just_now: "刚刚",
    kms: "公里",
    kms_away: "{{distance}} 公里远",
    knots: "结",
    last_current: "上次潮流",
    last_tide: "上次潮汐",
    loading: "正在加载",
    low: "低位",
    map: "地图",
    meters: "米",
    miles: "英里",
    miles_away: "{{distance}} 英里远",
    minute_ago: "{{minute}} 分钟前",
    minute_from_now: "{{minute}} 分钟后",
    minutes_ago: "{{minute}} 分钟前",
    minutes_from_now: "{{minute}} 分钟后",
    moon: "月亮",
    moonrise_now: "月出",
    moonrise_week: "月出",
    moonset_now: "月落",
    moonset_week: "月落",
    nearby_currents: "附近的潮流",
    nearby_tides: "附近的潮汐",
    next_current: "下次潮流",
    next_tide: "下次潮汐",
    now: "现在",
    recent_currents: "最近的潮流",
    recent_tides: "最近的潮汐",
    recents: "最近的",
    search: "搜索",
    search_placeholder: "搜索潮汐站",
    settings: "设置",
    show: "显示",
    slack: "潮憩",
    somethings_wrong: "发生错误",
    states: "州/省",
    sun: "太阳",
    sunrise_now: "日出",
    sunrise_week: "日出",
    sunset_now: "日落",
    sunset_week: "日落",
    this_station: "此站",
    tide_station: "潮汐站",
    tide_stations: "潮汐站",
    tides: "潮汐",
    today: "今天",
    tomorrow: "明天",
    try_again: "再试一次",
    turn_off_ads: "关闭广告三天",
    unit: "单位",
    watch_rewarded_video: "观看有奖视频",
    week: "周"
  }
};
