export default {
  translation: {
    app_name: "Marées proches de moi",
    app_version: "Version de l'appli",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Attribution",
    by_country: "Par pays",
    cities: "États",
    clear_recent_currents: "Effacer les ports récents (Courant)",
    clear_recent_tides: "Effacer les ports récents (Marée)",
    connection_error: "Erreur de connexion",
    contact_support: "Contacter l'assistance",
    current_station: "Port (Courant)",
    current_stations: "Les ports courant",
    currents: "Courants",
    distance: "Unités de mesure",
    ebb: "Jusant",
    favorite_currents: "Courants préférés",
    favorite_tides: "Marées préférées",
    flood: "Flot",
    following_current: "Courant suivant",
    hide: "masquer",
    high: "Haute",
    home: "Accueil",
    hour_ago: "Il y a {{hour}} heure",
    hour_from_now: "Dans {{hour}} heure",
    hour_minute_ago: "Il y a {{hour}} heure et {{minute}} minute",
    hour_minute_from_now: "Dans {{hour}} heure et {{minute}} minute",
    hour_minutes_ago: "Il y a {{hour}} heure et {{minute}} minutes",
    hour_minutes_from_now: "Dans {{hour}} heure et {{minute}} minutes",
    hours_ago: "Il y a {{hour}} heures",
    hours_from_now: "Dans {{hour}} heures",
    hours_minute_ago: "Il y a {{hour}} heures et {{minute}} minute",
    hours_minute_from_now: "Dans {{hour}} heures et {{minute}} minute",
    hours_minutes_ago: "Il y a {{hour}} heures et {{minute}} minutes",
    hours_minutes_from_now: "Dans {{hour}} heures et {{minute}} minutes",
    just_now: "À l'instant",
    kms: "km",
    kms_away: "À {{distance}} km",
    knots: "nds",
    last_current: "Dernier courant",
    last_tide: "Dernière marée",
    loading: "Chargement...",
    low: "Basse",
    map: "Carte",
    meters: "m",
    miles: "miles",
    miles_away: "À {{distance}} miles",
    minute_ago: "Il y a {{minute}} minute",
    minute_from_now: "Dans {{minute}} minute",
    minutes_ago: "Il y a {{minute}} minutes",
    minutes_from_now: "Dans {{minute}} minutes",
    moon: "Lune",
    moonrise_now: "Lever de lune",
    moonrise_week: "Lever",
    moonset_now: "Coucher de lune",
    moonset_week: "Coucher",
    nearby_currents: "Courants à proximité",
    nearby_tides: "Marées à proximité",
    next_current: "Prochain courant",
    next_tide: "Prochaine marée",
    now: "Maintenant",
    recent_currents: "Courants récents",
    recent_tides: "Marées récentes",
    recents: "Récentes",
    search: "Rechercher",
    search_placeholder: "Rechercher des ports marée",
    settings: "Réglages",
    show: "afficher",
    slack: "Étale",
    somethings_wrong: "Une erreur s'est produite",
    states: "Villes",
    sun: "Soleil",
    sunrise_now: "Lever du soleil",
    sunrise_week: "Lever",
    sunset_now: "Coucher du soleil",
    sunset_week: "Coucher",
    this_station: "Ce port",
    tide_station: "Port (Marée)",
    tide_stations: "Les ports marée",
    tides: "Marées",
    today: "Aujourd'hui",
    tomorrow: "Demain",
    try_again: "Réessayez",
    turn_off_ads: "Désactiver les pubs pendant trois jours",
    unit: "Unité",
    watch_rewarded_video: "Regarder une vidéo avec récompense",
    week: "Semaine"
  }
};
