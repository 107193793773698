import React, { Component } from "react";
import I18n from "../../config/locales";

class TideStations extends Component {
  distanceAway(tideStation) {
    if (tideStation.metric) {
      if (
        tideStation.distance_km !== undefined &&
        tideStation.distance_km !== null
      ) {
        return (
          <div className="text-muted">
            {I18n.t("kms_away", { distance: tideStation.distance_km })}
          </div>
        );
      }
    } else {
      if (
        tideStation.distance_mi !== undefined &&
        tideStation.distance_mi !== null
      ) {
        return (
          <div className="text-muted">
            {I18n.t("miles_away", { distance: tideStation.distance_mi })}
          </div>
        );
      }
    }
  }

  render() {
    const { tideStations, nearby } = this.props;
    if (tideStations === null || tideStations.length == 0) {
      return <div />;
    } else {
      const title = nearby ? I18n.t("nearby_tides") : I18n.t("tide_stations");
      return (
        <div className="card bg-tides3 mb-2">
          <div className="card-body text-white">
            <h2 className="pl-3">{title}</h2>
            <div className="list-group list-group-flush">
              {tideStations.map((tideStation) => (
                <a
                  href={"/tide_stations/" + tideStation.id}
                  className="list-group-item list-group-item-action bg-tides2 text-white"
                  key={tideStation.id}
                >
                  <div>{tideStation.name}</div>
                  {this.distanceAway(tideStation)}
                </a>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TideStations;
