export default {
  translation: {
    app_name: "타이드: 조수 및 해류 정보",
    app_version: "앱 버전",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "특성",
    by_country: "국가별",
    cities: "도",
    clear_recent_currents: "최근 해류 관측소 삭제",
    clear_recent_tides: "최근 조류 관측소 삭제",
    connection_error: "연결 오류",
    contact_support: "지원 문의",
    current_station: "현재 관측소",
    current_stations: "현재 관측소",
    currents: "해류",
    distance: "거리",
    ebb: "썰물",
    favorite_currents: "즐겨찾는 해류",
    favorite_tides: "즐겨찾는 조수",
    flood: "밀물",
    following_current: "이어지는 해류",
    hide: "숨김",
    high: "높음",
    home: "홈",
    hour_ago: "{{hour}}시간 전",
    hour_from_now: "앞으로 {{hour}}시간 후",
    hour_minute_ago: "{{hour}}시간 {{minute}}분 전",
    hour_minute_from_now: "앞으로 {{hour}}시간 {{minute}}분 후",
    hour_minutes_ago: "{{hour}}시간 {{minute}}분 전",
    hour_minutes_from_now: "앞으로 {{hour}}시간 {{minute}}분 후",
    hours_ago: "{{hour}}시간 전",
    hours_from_now: "앞으로 {{hour}}시간 후",
    hours_minute_ago: "{{hour}}시간 {{minute}}분 전",
    hours_minute_from_now: "앞으로 {{hour}}시간 {{minute}}분 후",
    hours_minutes_ago: "{{hour}}시간 {{minute}}분 전",
    hours_minutes_from_now: "앞으로 {{hour}}시간 {{minute}}분 후",
    just_now: "방금",
    kms: "km",
    kms_away: "{{distance}} km 인근",
    knots: "노트",
    last_current: "지난 해류",
    last_tide: "지난 조수",
    low: "낮음",
    map: "지도",
    meters: "미터",
    miles: "mi",
    miles_away: "{{distance}} mi 인근",
    minute_ago: "{{minute}}분 전",
    minute_from_now: "앞으로 {{minute}}분 후",
    minutes_ago: "{{minute}}분 전",
    minutes_from_now: "앞으로 {{minute}}분 후",
    moon: "달",
    moonrise_now: "월출",
    moonrise_week: "월출",
    moonset_now: "월몰",
    moonset_week: "월몰",
    nearby_currents: "근처 해류",
    nearby_tides: "근처 조수",
    next_current: "다음 해류",
    next_tide: "다음 조수",
    now: "지금",
    recent_currents: "최근 해류",
    recent_tides: "최근 조수",
    recents: "최근",
    search: "검색",
    search_placeholder: "조수 관측소 검색",
    settings: "설정",
    show: "표시",
    slack: "정조",
    somethings_wrong: "오류가 발생했습니다",
    states: "도시",
    sun: "해",
    sunrise_now: "일출",
    sunrise_week: "월출",
    sunset_now: "일몰",
    sunset_week: "월몰",
    this_station: "이 관측소",
    tide_station: "조수 관측소",
    tide_stations: "조수 관측소",
    tides: "조수",
    today: "오늘",
    tomorrow: "내일",
    try_again: "다시 시도",
    turn_off_ads: "3일간 광고 끄기",
    unit: "단위",
    watch_rewarded_video: "리워드 비디오 보기",
    week: "주"
  }
};
