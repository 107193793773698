export default {
  translation: {
    app_name: "Tidevann i nærheten",
    app_version: "Appversjon", // google translate
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Tillegg",
    by_country: "Etter land",
    cities: "Administrative grenser",
    clear_recent_currents: "Fjern nylige havstrømsstasjoner",
    clear_recent_tides: "Fjern nylige tidevannsstasjoner",
    connection_error: "Tilkoblingsfeil",
    contact_support: "Contact Support",
    current_station: "Gjeldende stasjon",
    current_stations: "Havstrømsstasjoner",
    currents: "Havstrømmer",
    distance: "Avstand",
    ebb: "Fjære",
    favorite_currents: "Havstrømmer i favoritter",
    favorite_tides: "Tidevann i favoritter",
    flood: "Flom",
    following_current: "Kommende havstrøm",
    hide: "gjemme seg", // google translate
    high: "Høy",
    home: "Hjem",
    hour_ago: "{{hour}} time siden",
    hour_from_now: "{{hour}} time fra nå",
    hour_minute_ago: "{{hour}} time og {{minute}} minutt siden",
    hour_minute_from_now: "{{hour}} time og {{minute}} minutt fra nå",
    hour_minutes_ago: "{{hour}} time og {{minute}} minutter siden",
    hour_minutes_from_now: "{{hour}} time og {{minute}} minutter fra nå",
    hours_ago: "{{hour}} timer siden",
    hours_from_now: "{{hour}} timer fra nå",
    hours_minute_ago: "{{hour}} timer og {{minute}} minutt siden",
    hours_minute_from_now: "{{hour}} timer og {{minute}} minutt fra nå",
    hours_minutes_ago: "{{hour}} timer og {{minute}} minutter siden",
    hours_minutes_from_now: "{{hour}} timer og {{minute}} minutter fra nå",
    just_now: "Akkurat nå",
    kms: "km",
    kms_away: "{{distance}} kilometer unna",
    knots: "knop",
    last_current: "Forrige havstrøm",
    last_tide: "Forrige tidevann",
    loading: "Laster…",
    low: "Lav",
    map: "Kart",
    meters: "m",
    miles: "miles",
    miles_away: "{{distance}} miles unna",
    minute_ago: "{{minute}} minutt siden",
    minute_from_now: "{{minute}} minutt fra nå",
    minutes_ago: "{{minute}} minutt siden",
    minutes_from_now: "{{minute}} minutter fra nå",
    moon: "Måne",
    moonrise_now: "Måneoppgang",
    moonrise_week: "Oppgang",
    moonset_now: "Månenedgang",
    moonset_week: "Nedgang",
    nearby_currents: "Havstrømmer i nærheten",
    nearby_tides: "Tidevann i nærheten",
    next_current: "Neste havstrøm",
    next_tide: "Neste tidevann",
    now: "Nå",
    recent_currents: "Nylige havstrømmer",
    recent_tides: "Nylige tidevann",
    recents: "siste", // google translate
    search: "Søk",
    search_placeholder: "Søk etter tidevannsstasjoner",
    settings: "Innstillinger",
    show: "vise fram", // google translate
    slack: "Stille vann",
    somethings_wrong: "Noe er galt",
    states: "Byer",
    sun: "Sol",
    sunrise_now: "Soloppgang",
    sunrise_week: "Oppgang",
    sunset_now: "Solnedgang",
    sunset_week: "Nedgang",
    this_station: "Denne stasjonen",
    tide_station: "Tidevannstasjon",
    tide_stations: "Tidevannsstasjoner",
    tides: "Tidevann",
    today: "I dag",
    tomorrow: "I morgen",
    try_again: "Prøv igjen",
    turn_off_ads: "Slå av annonser i tre dager", // google translate
    unit: "Måleenhet",
    watch_rewarded_video: "Se belønnet video", // google translate
    week: "Uke"
  }
};
