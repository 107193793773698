export default {
  translation: {
    app_name: "Tides Near Me",
    app_version: "App Version",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Attribution",
    by_country: "By Country",
    cities: "Cities",
    clear_recent_currents: "Clear Recent Current Stations",
    clear_recent_tides: "Clear Recent Tide Stations",
    connection_error: "Connection Error",
    contact_support: "Contact Support",
    contact_support: "Contact Support",
    current_station: "Current Station",
    current_stations: "Current Stations",
    currents: "Currents",
    distance: "Distance",
    ebb: "Ebb",
    favorite_currents: "Favorite Currents",
    favorite_tides: "Favorite Tides",
    flood: "Flood",
    following_current: "Following Current",
    hide: "hide",
    hide: "hide",
    high: "High",
    home: "Home",
    hour_ago: "{{hour}} hour ago",
    hour_from_now: "{{hour}} hour from now",
    hour_minute_ago: "{{hour}} hour, {{minute}} minute ago",
    hour_minute_from_now: "{{hour}} hour, {{minute}} minute from now",
    hour_minutes_ago: "{{hour}} hour, {{minute}} minutes ago",
    hour_minutes_from_now: "{{hour}} hour, {{minute}} minutes from now",
    hours_ago: "{{hour}} hours ago",
    hours_from_now: "{{hour}} hours from now",
    hours_minute_ago: "{{hour}} hours, {{minute}} minute ago",
    hours_minute_from_now: "{{hour}} hours, {{minute}} minute from now",
    hours_minutes_ago: "{{hour}} hours, {{minute}} minutes ago",
    hours_minutes_from_now: "{{hour}} hours, {{minute}} minutes from now",
    just_now: "Just now",
    kms: "kms",
    kms_away: "{{distance}} kms away",
    knots: "kts",
    last_current: "Last Current",
    last_tide: "Last Tide",
    loading: "Loading...",
    low: "Low",
    map: "Map",
    meters: "m",
    miles: "miles",
    miles_away: "{{distance}} miles away",
    minute_ago: "{{minute}} minute ago",
    minute_from_now: "{{minute}} minute from now",
    minutes_ago: "{{minute}} minutes ago",
    minutes_from_now: "{{minute}} minutes from now",
    moon: "Moon",
    moonrise_now: "Moonrise",
    moonrise_week: "Rise",
    moonset_now: "Moonset",
    moonset_week: "Set",
    nearby_currents: "Nearby Currents",
    nearby_tides: "Nearby Tides",
    next_current: "Next Current",
    next_tide: "Next Tide",
    now: "Now",
    recent_currents: "Recent Currents",
    recent_tides: "Recent Tides",
    recents: "Recents",
    recents: "Recents",
    search: "Search",
    search_placeholder: "Search for tide stations",
    settings: "Settings",
    show: "show",
    show: "show",
    slack: "Slack",
    somethings_wrong: "Something's Wrong",
    states: "States",
    subtitle: "Find Nearby Tides and Currents",
    sun: "Sun",
    sunrise_now: "Sunrise",
    sunrise_week: "Rise",
    sunset_now: "Sunset",
    sunset_week: "Set",
    this_station: "This station",
    tide_station: "Tide Station",
    tide_stations: "Tide Stations",
    tides: "Tides",
    today: "Today",
    tomorrow: "Tomorrow",
    try_again: "Try Again",
    turn_off_ads: "Turn off ads for three days",
    unit: "Unit",
    watch_rewarded_video: "Watch Rewarded Video",
    week: "Week",
  },
};
