import React, { Component } from "react";

import ItunesStore from "images/home/itunes-store.svg";
import GooglePlay from "images/home/google-play.svg";

class MobileStoreButtons extends Component {
  render() {
    return (
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col">
            <a href="https://apps.apple.com/us/app/tides-near-me/id585223877">
              <img
                src={ItunesStore}
                style={{ marginLeft: "6%", marginRight: "6%", width: "88%" }}
              />
            </a>
          </div>
          <div className="col">
            <a href="https://play.google.com/store/apps/details?id=me.tidesnear.free">
              <img src={GooglePlay} style={{ width: "100%" }} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileStoreButtons;
