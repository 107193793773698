import React, { Component } from "react";
import I18n from "../../../config/locales";
import {
  relativeTimeFromNow,
  timeDisplay,
  timezoneIfDifferent
} from "../../../config/datetime";
import UpArrow from "images/tides/up.png";
import DownArrow from "images/tides/down.png";
import Sun from "images/tides/sun.png";
import Moon from "images/tides/moon.png";

class Now extends Component {
  tideHeadline(tide, type) {
    if (tide.high) {
      return (
        <div>
          {I18n.t(type) + " - " + I18n.t("high")}
          <img className="ml-2 mb-2" src={UpArrow} />
        </div>
      );
    } else {
      return (
        <div>
          {I18n.t(type) + " - " + I18n.t("low")}
          <img className="ml-2 mb-2" src={DownArrow} />
        </div>
      );
    }
  }

  solarHeadline(solar) {
    var str;
    if (solar.rise) {
      str = I18n.t("sunrise_now");
    } else {
      str = I18n.t("sunset_now");
    }
    return (
      <div>
        <img className="mr-2 mb-2" src={Sun} />
        {str}
      </div>
    );
  }

  lunarHeadline(lunar) {
    var str;
    if (lunar.rise) {
      str = I18n.t("moonrise_now");
    } else {
      str = I18n.t("moonset_now");
    }
    return (
      <div>
        <img className="mr-2 mb-2" src={Moon} />
        {str}
      </div>
    );
  }

  render() {
    const { tideStation } = this.props;
    return (
      <div>
        <div className="card text-center bg-tides1 mb-1 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.tideHeadline(tideStation.last_tide, "last_tide")}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(tideStation.last_tide.timestamp)}
                {timezoneIfDifferent(tideStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(tideStation.last_tide.timestamp)}
            </div>
          </div>
        </div>
        <div className="card text-center bg-tides2 mb-1 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.tideHeadline(tideStation.next_tide, "next_tide")}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(tideStation.next_tide.timestamp)}
                {timezoneIfDifferent(tideStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(tideStation.next_tide.timestamp)}
            </div>
          </div>
        </div>
        <div className="card text-center bg-tides4 mb-1 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.solarHeadline(tideStation.next_solar)}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(tideStation.next_solar.timestamp)}
                {timezoneIfDifferent(tideStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(tideStation.next_solar.timestamp)}
            </div>
          </div>
        </div>
        <div className="card text-center bg-tides6 py-0">
          <div className="card-body text-white">
            <div className="h4 my-0">
              {this.lunarHeadline(tideStation.next_lunar)}
            </div>
            <div className="my-0">
              <time className="h1">
                {timeDisplay(tideStation.next_lunar.timestamp)}
                {timezoneIfDifferent(tideStation.timezone, " ")}
              </time>
            </div>
            <div className="h6 mb-0 mt-1">
              {relativeTimeFromNow(tideStation.next_lunar.timestamp)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Now;
