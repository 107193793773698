export default {
  translation: {
    app_name: "Gezeiten in meiner Nähe",
    app_version: "App-Version",
    attribution_body:
      "Map data © OpenStreetMap contributors\n\nMap layer by Esri",
    attribution_title: "Attribute",
    by_country: "Nach Land",
    cities: "Bundesländer",
    clear_recent_currents: "Neuste Strömungsstationen löschen",
    clear_recent_tides: "Neuste Gezeitenstationen löschen",
    connection_error: "Verbindungsfehler",
    contact_support: "Support kontaktieren",
    current_station: "Strömungsstation",
    current_stations: "Strömungsstationen",
    currents: "Meeresströmungen",
    distance: "Entfernung",
    ebb: "Ebbe",
    favorite_currents: "Lieblingsströme",
    favorite_tides: "Lieblingsgezeiten",
    flood: "Flut",
    following_current: "Darauf folgende Meeresströmung",
    hide: "verbergen",
    high: "Hoch",
    home: "Startseite",
    hour_ago: "vor {{hour}} Stunde",
    hour_from_now: "in {{hour}} Stunde ab jetzt",
    hour_minute_ago: "vor {{hour}} Stunde und {{minute}} Minute",
    hour_minute_from_now: "in {{hour}} Stunde und {{minute}} Minute ab jetzt",
    hour_minutes_ago: "vor {{hour}} Stunde und {{minute}} Minuten",
    hour_minutes_from_now: "in {{hour}} Stunde und {{minute}} Minuten ab jetzt",
    hours_ago: "vor {{hour}} Stunden",
    hours_from_now: "in {{hour}} Stunden ab jetzt",
    hours_minute_ago: "vor {{hour}} Stunden und {{minute}} Minute",
    hours_minute_from_now: "in {{hour}} Stunden und {{minute}} Minute ab jetzt",
    hours_minutes_ago: "vor {{hour}} Stunden und {{minute}} Minuten",
    hours_minutes_from_now:
      "in {{hour}} Stunden und {{minute}} Minuten ab jetzt",
    just_now: "Gerade eben",
    kms: "km",
    kms_away: "{{distance}} km entfernt",
    knots: "Knoten",
    last_current: "Vorhergehende Meeresströmung",
    last_tide: "Vorhergehende Gezeiten",
    loading: "Wird geladen...",
    low: "Niedrig",
    map: "Karte",
    meters: "m",
    miles: "Meilen",
    miles_away: "{{distance}} Meilen entfernt",
    minute_ago: "vor {{minute}} Minute",
    minute_from_now: "in {{minute}} Minute ab jetzt",
    minutes_ago: "vor {{minute}} Minuten",
    minutes_from_now: "in {{minute}} Minuten ab jetzt",
    moon: "Mond",
    moonrise_now: "Mondaufgang",
    moonrise_week: "Aufgang",
    moonset_now: "Monduntergang",
    moonset_week: "Untergang",
    nearby_currents: "Meeresströmungen in der Nähe",
    nearby_tides: "Gezeiten in der Nähe",
    next_current: "Nächste Meeresströmung",
    next_tide: "Nächste Gezeiten",
    now: "Jetzt",
    recent_currents: "Neuste Meeresströmungen",
    recent_tides: "Neuste Gezeiten",
    recents: "Neuste",
    search: "Suchen",
    search_placeholder: "Suche nach Gezeitenstationen",
    settings: "Einstellungen",
    show: "anzeigen",
    slack: "Stauwasser",
    somethings_wrong: "Es ist ein Fehler aufgetreten",
    states: "Städte",
    sun: "Sonne",
    sunrise_now: "Sonnenaufgang",
    sunrise_week: "Aufgang",
    sunset_now: "Sonnenuntergang",
    sunset_week: "Untergang",
    this_station: "Diese Station",
    tide_station: "Gezeitenstation",
    tide_stations: "Gezeitenstationen",
    tides: "Gezeiten",
    today: "Heute",
    tomorrow: "Morgen",
    try_again: "Erneut versuchen",
    turn_off_ads: "Werbung für drei Tage entfernen",
    unit: "Einheit",
    watch_rewarded_video: "Belohnungsvideo anschauen",
    week: "Woche"
  }
};
